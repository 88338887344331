<template>
  <div :style="direction">
    <header>
      <NavBar />
      <HeroView />
    </header>
    <main>
      <div class="container">
        <AboutView />
        <OverviewView />
        <VisionView />
        <GoalsView />
        <ServicesView />
        <SpecificationView />
      </div>
      <CommitmentView />
      <ContactView />
    </main>
    <footer class="bg-primary-200 py-6">
      <FotterComponent />
    </footer>
  </div>
</template>
<script setup lang="ts">
import NavBar from '@/core/components/NavBar.vue';
import AboutView from '@/views/about/AboutView.vue';
import HeroView from '@/views/hero/HeroView.vue';
import OverviewView from '@/views/overview/OverviewView.vue';
import VisionView from '@/views/vision/VisionView.vue';
import GoalsView from '@/views/goals/GoalsView.vue';
import ServicesView from '@/views/services/ServicesView.vue';
import SpecificationView from '@/views/specification/SpecificationView.vue';
import CommitmentView from '@/views/commitment/CommitmentView.vue';
import ContactView from '@/views/contact/ContactView.vue';
import FotterComponent from "@/core/components/FotterComponent.vue"

import { langStore } from "@/core/store/lang"
import { CSSProperties, computed } from 'vue';

const direction = computed<CSSProperties>(() => {
  return { direction: langStore.lang == 'en' ? 'ltr' : 'rtl' }
})

</script>
  
  