import { reactive } from "vue"

const langStore = reactive<{
    lang: string,
    init: () => void,
    change: (lang: string) => void
}>({
    lang: "ar",
    init () { 
        const lang = localStorage.getItem("lang")
        lang ? this.lang = lang : this.change("ar")
     },
    change (lang) { 
        localStorage.setItem("lang", lang)
        this.lang = lang
    },
})

export {
    langStore
}
