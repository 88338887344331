<template>
  <div
    v-if="goals"
    id="goals"
    class="pt-6 mt-14 text-center"
  >
    <h3 class="text-primary-100 text-2xl md:text-3xl font-bold">
      {{ langStore.lang == 'en' ? 'Goals' : 'أهدافنا ' }}
    </h3>
    <div class="grid lg:grid-cols-2 gap-12 mt-10">
      <template
        v-for="goal in goals"
        :key="goal.id"
      >
        <div
          class="w-4/5 mx-auto flex flex-col items-center justify-center gap-5 p-4 shadow-card hover:bg-primary-100 hover:text-white transition-all duration-200"
        >
          <img
            :src="goal.image"
            alt=""
          >
          <p class="md:text-xl font-bold">
            {{ langStore.lang == 'en' ? goal.title : goal.titleArabic }}
          </p>
          <p class="md:text-xl leading-[2rem]">
            {{ langStore.lang == 'en' ? goal.description : goal.descriptionArabic }}
          </p>
        </div>
      </template>
    </div>
  </div>
</template>
  
<script setup lang="ts">

import { onMounted, ref } from 'vue';
import { getGoals } from './goals-service';
import { langStore } from "@/core/store/lang";
import { Goal } from './models/goal';

const goals = ref<Goal[]>()

onMounted(() => {
  getGoals()
    .then(response => goals.value = response)
})

</script>