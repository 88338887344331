<template>
  <div
    v-if="service"
    id="services"
    class="pt-6 mt-14 flex justify-center"
  >
    <img
      class="w-[80rem]"
      :src="service.image"
      alt=""
    >
  </div>
</template>
  
<script setup lang="ts">

import { onMounted, ref } from 'vue';
import { Service } from './models/service';
import { getService } from './services-service';

const service = ref<Service>()

onMounted(() => {
  getService()
        .then(response => service.value = response)
})

</script>