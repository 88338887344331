import { apiClient } from "@/core/api/http-common"
import { Goal } from "./models/goal"

export const getGoals = () => {
    return apiClient.get<Goal[]>(`/goals`)
        .then(response => response.data)
}




