<template>
  <div
    v-if="specifications"
    id="specifications"
    class="pt-6 mt-14 flex flex-col items-center"
  >
    <h3 class="text-primary-100 text-2xl md:text-3xl font-bold text-center">
      {{ langStore.lang == 'en' ? 'Specifications of the management' : 'مواصفات الإدارة' }}
    </h3>
    <div class="flex mt-6">
      <div
        class="mt-12 flex flex-col gap-y-16 p-4 lg:p-8 "
        :class="langStore.lang == 'en' ? ' md:text-right' : 'border-l-4 border-primary-100 border-dashed md:text-left'"
      >
        <div
          v-for="(specification, index) in specifications"
          :key="specification.id"
        >
          <p v-if="index % 2 !== 0">
            {{ langStore.lang == 'en' ? specification.specifications : specification.specificationsArabic }}
          </p>
        </div>
      </div>

      <div 
        class="mt-12 flex flex-col gap-y-16 p-4 lg:p-8 "
        :class="langStore.lang == 'en' ? 'border-l-4 border-primary-100 border-dashed ' : ''"
      >
        <div
          v-for="(specification, index) in specifications"
          :key="specification.id"
        >
          <p v-if="index % 2 === 0">
            {{ langStore.lang == 'en' ? specification.specifications : specification.specificationsArabic }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup lang="ts">

import { onMounted, ref } from 'vue';
import { getSpecifications } from './specification-service';
import { Specification } from './models/specification';
import { langStore } from "@/core/store/lang";

const specifications = ref<Specification[]>()

onMounted(() => {
  getSpecifications()
    .then(response => specifications.value = response)
})

</script>