<template>
  <div
    v-if="contact"
    id="contact"
    class="container py-20 grid md:grid-cols-2"
  >
    <div>
      <p class="text-2xl md:text-3xl font-bold">
        {{ langStore.lang == 'en' ? 'Contact' : 'تواصل' }}
        <span class="text-primary-100">
          {{ langStore.lang == 'en' ? 'Us' : 'معنا' }}
        </span>
      </p>
      <p class="text-2xl mt-4">
        {{ langStore.lang == 'en' ? contact.title : contact.titleArabic }}
      </p>
      <p class="mt-2">
        {{ langStore.lang == 'en' ? `Address : ${contact.address}` :
          `العنوان : ${contact.addressArabic}` }}
      </p>
      <p class="mt-2">
        {{ langStore.lang == 'en' ? `Phone : ${contact.phone}` :
          `الهاتف : [${contact.phone}]` }}
      </p>
    </div>
    <div class="h-80 mt-12 md:mt-0">
      <iframe
        class="w-full h-full"
        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3351.03767538!2d13.227472315184428!3d32.870722580943806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzLCsDUyJzE0LjYiTiAxM8KwMTMnNDYuOCJF!5e0!3m2!1sen!2seg!4v1677750475406!5m2!1sen!2seg"
        width="600"
        height="450"
        style="border: 0"
        allowfullscreen="false"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />
    </div>
  </div>
</template>
  
<script setup lang="ts">

import { onMounted, ref } from 'vue';
import { Contact } from './models/contact';
import { getContact } from './contact-service';
import { langStore } from "@/core/store/lang";

const contact = ref<Contact>()

onMounted(() => {
  getContact()
    .then(response => contact.value = response)
})

</script>