import { createApp } from "vue";
import App from "./App.vue";
import "./assets/styles/index.css";
import { langStore } from "./core/store/lang";
import {
  executeRequests
} from "./core/store/data-store";

const app = createApp(App);

langStore.init();
executeRequests();
app.mount("#app");

