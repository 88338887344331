import { apiClient } from "@/core/api/http-common"
import { Vision} from "./models/vision"

export const getVision = () => {
    return apiClient.get<Vision>(`/vision `)
        .then(response => response.data)
}




