<template>
  <div
    v-if="hero"
    :style="getBackgroundImage(hero.image)"
    class="h-[calc(100vh-8rem)] bg-cover bg-center"
  >
    <div class="container flex flex-col justify-center h-full">
      <p class="text-white text-xl sm:text-3xl lg:text-5xl max-w-md leading-[2rem!important] lg:leading-[4rem!important]">
        {{ langStore.lang == 'en' ? hero.title : hero.titleArabic }}
      </p>
      <div class="flex flex-col sm:flex-row gap-2 mt-8">
        <a
          href="#contact"
          class="w-36 md:w-44 text-lg text-center py-2 bg-primary-100 border border-primary-100 text-white hover:bg-transparent hover:text-primary-100 transition-all duration-200 cursor-pointer"
        >
          {{ langStore.lang == 'en' ? 'Contact Us' : ' تواصل معنا ' }}
        </a>
        <a
          href="#about"
          class="w-36 md:w-44 text-lg text-center py-2 bg-white border border-white hover:bg-transparent hover:text-white transition-all duration-200 cursor-pointer"
        >
          {{ langStore.lang == 'en' ? 'Read More' : 'قراءة المزيد' }}
        </a>
      </div>
    </div>
  </div>
</template>
  
<script setup lang="ts">

import { onMounted, ref } from 'vue';
import { Hero } from './models/hero';
import { getHero } from './hero-service';
import { langStore } from "@/core/store/lang";

const hero = ref<Hero>()

onMounted(() => {
  getHero()
    .then(response => hero.value = response)
})

const getBackgroundImage = (url: string) => ({
  backgroundImage: `url(${url})`
});

</script>