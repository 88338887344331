import { apiClient } from "@/core/api/http-common"
import { Contact } from "./models/contact"

export const getContact = () => {
    return apiClient.get<Contact>(`/contact `)
        .then(response => response.data)
}




