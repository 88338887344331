import { About } from "@/views/about/models/about";
import { Commitment } from "@/views/commitment/models/commitment";
import { Contact } from "@/views/contact/models/contact";
import { Goal } from "@/views/goals/models/goal";
import { Hero } from "@/views/hero/models/hero";
import { Overview } from "@/views/overview/models/overview";
import { Service } from "@/views/services/models/service";
import { Specification } from "@/views/specification/models/specification";
import { Vision } from "@/views/vision/models/vision";

import { getHero } from "@/views/hero/hero-service";
import { getAbout } from "@/views/about/about-service";
import { getVision } from "@/views/vision/vision-service";
import { getOverview } from "@/views/overview/overview-service";
import { getGoals } from "@/views/goals/goals-service";
import { getService } from "@/views/services/services-service";
import { getSpecifications } from "@/views/specification/specification-service";
import { getCommitment } from "@/views/commitment/commitment-service";
import { getContact } from "@/views/contact/contact-service";

import { ref } from "vue";

const hero = ref<Hero>();
const about = ref<About>();
const vision = ref<Vision>();
const overview = ref<Overview>();
const goals = ref<Goal[]>();
const service = ref<Service>();
const specifications = ref<Specification[]>();
const commitment = ref<Commitment>();
const contact = ref<Contact>();

const executeRequests = async () => {
    try {
      hero.value = await getHero();
      about.value = await getAbout();
      vision.value = await getVision();
      overview.value = await getOverview();
      goals.value = await getGoals();
      service.value = await getService();
      specifications.value = await getSpecifications();
      commitment.value = await getCommitment();
      contact.value = await getContact();
  
      // Process the responses as needed
    } catch (error) {
      console.error(error);
    }
  };

export { commitment, about, contact, goals, hero, overview, service, specifications, vision, executeRequests };
