<template>
  <nav class="bg-white w-full fixed top-0 shadow-[0_0_4px_1px_#969696] lg:static flex items-center px-4 lg:px-8 py-2">
    <BurgerMenu />
    <img
      class="w-28 md:w-40 mr-auto"
      src="../../assets/images/logo.png"
      alt=""
    >

    <div
      id="dropdown"
      class="bg-white flex flex-col fixed top-0 h-screen px-2 lg:py-2 lg:mt-0 z-50 transition-all duration-300 w-72 lg:static lg:h-auto lg:flex-row lg:justify-center lg:shadow-none lg:gap-4 xl:gap-8 lg:w-full text-center lg:items-center"
      :class="isSideBarOpen ? 'right-0 shadow-[0_0_4px_1px_#969696] ' : '-right-72'"
    >
      <BurgerMenu custom-style="mt-5 mr-3 md:mt-7 md:mr-2" />

      <div
        v-for="item in navigation"
        :key="item.title"
      >
        <a
          class="hover:bg-amber-200 py-2 px-6 rounded-md"
          :href="item.route"
          @click="isSideBarOpen = false"
        >
          {{ langStore.lang == 'en' ? item.title : item.titleArabic }}
        </a>
      </div>
      <button
        class="hover:bg-amber-200 py-2 px-6 rounded-md"
        @click="changeLang"
      >
        {{ langStore.lang == 'en' ? 'العربية' : 'English' }}
      </button>
    </div>
  </nav>
</template>
<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref } from "vue";
import BurgerMenu from "./svgs/burgerMenu.vue"
import { isSideBarOpen } from "@/core/store/sideBar"
import { langStore } from "@/core/store/lang"

const containerRef = ref<HTMLElement | null>(null);
const navigation = [
    { titleArabic: 'الرئيسية', title:"Home", route: '' },
    { titleArabic: 'من نحن', title:"About Us", route: '#about' },
    { titleArabic: 'أهدافنا', title:"Goals", route: '#goals' },
    { titleArabic: 'خدماتنا', title:"Services", route: '#services' },
    { titleArabic: 'المواصفات الإدارية', title:"Specifications", route: '#specifications' },
    { titleArabic: 'تواصل معنا', title:"Contact Us", route: '#contact' }
]

const handleClickOutside = (event: MouseEvent) => {
  if (!containerRef.value || !containerRef.value.contains(event.target as Node)) {
    if (isSideBarOpen.value) isSideBarOpen.value = false
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

const changeLang = () => {
  const lang = langStore.lang == "en" ? "ar" : "en"
  langStore.change(lang)
}
</script>
