import { apiClient } from "@/core/api/http-common"
import {  Hero } from "./models/hero"

const getHero = () => {
    return apiClient.get<Hero>(`/hero `)
        .then(response => response.data)
}

export {
    getHero
}




