import { apiClient } from "@/core/api/http-common"
import { Service } from "./models/service"

export const getService = () => {
    return apiClient.get<Service>(`/services`)
        .then(response => response.data)
}




