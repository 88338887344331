<template>
  <div
    v-if="about"
    id="about"
    class="flex flex-col lg:flex-row justify-center items-center gap-6 pt-6 mt-14"
  >
    <div>
      <p class="text-2xl md:text-3xl font-bold">
        {{ langStore.lang == 'en' ? about.titleBlack : about.titleBlackArabic }}
        <span class="text-primary-100"> {{ langStore.lang == 'en' ? about.titleGold : about.titleGoldArabic }}</span>
      </p>
      <p class="max-w-3xl mt-3 md:text-xl leading-[2rem!important]">
        {{ langStore.lang == 'en' ? about.description : about.descriptionArabic }}
      </p>
    </div>
    <img
      class="w-60 lg:w-[400px]"
      :src="about.image"
      alt=""
    >
  </div>
</template>
  
<script setup lang="ts">

import { onMounted, ref } from 'vue';
import { About } from './models/about';
import { getAbout } from './about-service';
import { langStore } from "@/core/store/lang";

const about = ref<About>()

onMounted(() => {
  getAbout()
    .then(response => about.value = response)
})

</script>