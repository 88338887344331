import { apiClient } from "@/core/api/http-common"
import { Overview } from "./models/overview"

export const getOverview = () => {
    return apiClient.get<Overview>(`/overview`)
        .then(response => response.data)
}




