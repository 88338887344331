<template>
  <div class="container flex flex-col sm:flex-row justify-center items-center gap-6 sm:gap-16">
    <div class="flex flex-col">
      <h3 class="text-white">
        {{ langStore.lang == 'en' ? 'All rights reserved @2023' : 'كل الحقوق محفوظة 2023 @' }}
      </h3>
    </div>
    <div
      class="flex gap-4 col-span-2 md:col-span-1"
      x-data="{icons: ['./icons/facebook.svg', './icons/twitter.svg', './icons/youtube.svg']}"
    >
      <div
        v-for="(icon, index) in icons"
        :key="index"
      >
        <a :href="icon.route">
          <div class="bg-primary-100 p-2 rounded-[50%]">
            <component :is="icon.icon" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import FacebookIcon from './svgs/FacebookIcon.vue';
import TwitterIcon from './svgs/TwitterIcon.vue';
import YoutubeIcon from './svgs/YoutubeIcon.vue';
import { langStore } from "@/core/store/lang";

const icons = [
  { icon: FacebookIcon, route: "" },
  { icon: TwitterIcon, route: "" },
  { icon: YoutubeIcon, route: "" },
]
</script>