<template>
  <div
    v-if="overview"
    id="overview"
    class="text-center mt-20"
  >
    <p class="text-2xl md:text-3xl font-bold">
      <span class="text-primary-100"> {{ langStore.lang == 'en' ? overview.titleGold : overview.titleGoldArabic }}</span>
      {{ langStore.lang == 'en' ? overview.titleBlack : overview.titleBlackArabic }}
    </p>
    <p class="max-w-3xl mx-auto mt-8 md:text-xl leading-[2rem!important]">
      {{ langStore.lang == 'en' ? overview.description : overview.descriptionArabic }}
    </p>
  </div>
</template>
  
<script setup lang="ts">

import { onMounted, ref } from 'vue';
import { Overview } from './models/overview';
import { getOverview } from './overview-service';
import { langStore } from "@/core/store/lang";

const overview = ref<Overview>()

onMounted(() => {
  getOverview()
        .then(response => overview.value = response)
})

</script>