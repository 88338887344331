<template>
  <div
    v-if="vision"
    class="grid lg:grid-cols-2 gap-2 mt-20"
  >
    <div>
      <h4
        class="text-primary-100 text-2xl md:text-3xl font-bold text-center mt-6 lg:mt-0"
        :class="langStore.lang == 'en' ? 'lg:text-left' : 'lg:text-right'"
      >
        {{ langStore.lang == 'en' ? vision.title : vision.titleArabic }}
      </h4>
      <p
        class="max-w-2xl mx-auto lg:mx-0 mt-3 md:text-xl leading-[2rem!important] text-center"
        :class="langStore.lang == 'en' ? 'lg:text-left' : 'lg:text-right'"
      >
        {{ langStore.lang == 'en' ? vision.description : vision.descriptionArabic }}
      </p>
    </div>
    <img
      class="mx-auto lg:mx-0 w-11/12"
      :src="vision.image"
      alt=""
    > 
  </div> 
</template>
  
<script setup lang="ts">

import { onMounted, ref } from 'vue';
import { Vision } from './models/vision';
import { getVision } from './vision-service';
import { langStore } from "@/core/store/lang";

const vision = ref<Vision>()

onMounted(() => {
  getVision()
    .then(response => vision.value = response)
})

</script>