import { apiClient } from "@/core/api/http-common"
import { Commitment } from "./models/commitment"

export const getCommitment = () => {
    return apiClient.get<Commitment>(`/commitment`)
        .then(response => response.data)
}




