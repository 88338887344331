import { apiClient } from "@/core/api/http-common"
import { Specification } from "./models/specification"

export const getSpecifications = () => {
    return apiClient.get<Specification[]>(`/specifications`)
        .then(response => response.data)
}




