<template>
  <div
    v-if="commitment"
    class="px-4 mt-20 h-[35rem] flex flex-col justify-center bg-cover bg-right bg-fixed text-white text-center"
    :style="getBackgroundImage(commitment.image)"
  >
    <h3 class="text-3xl md:text-5xl font-bold">
      {{ langStore.lang == 'en' ? commitment.title : commitment.titleArabic }}
    </h3>
    <p class="max-w-4xl mx-auto mt-7 text-lg md:text-2xl leading-[2rem]">
      {{ langStore.lang == 'en' ? commitment.description : commitment.descriptionArabic }}
    </p>
  </div>
</template>
  
<script setup lang="ts">

import { onMounted, ref } from 'vue';
import { Commitment } from './models/commitment';
import { getCommitment } from './commitment-service';
import { langStore } from "@/core/store/lang";

const commitment = ref<Commitment>()

onMounted(() => {
  getCommitment()
    .then(response => commitment.value = response)
})

const getBackgroundImage = (url: string) => ({
  backgroundImage: `url(${url})`
});

</script>